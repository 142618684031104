<template>
  <div id="app" class="flex flex-col items-center min-h-screen bg-[#F9F5FA] p-4 md:p-8">
    <header class="w-full flex justify-between items-center mb-8 md:mb-6">
      <h1 class="text-3xl font-bold text-[#71537A]">Ally</h1>
    </header>
    
    <div class="flex flex-col w-full max-w-7xl">
      <div class="flex flex-col md:flex-row w-full mb-1">
        <div class="bg-white border border-[#DED7E0] rounded-3xl p-8 md:p-16 mb-5 w-full md:w-2/3 md:mr-8">
          <div class="flex items-center justify-between mb-3 md:mb-16">
            <button @click="previousPrompt" class="text-3xl text-[#71537A] hover:text-[#8A6A94]">&lsaquo;</button>
            <h2 class="text-2xl md:text-3xl text-center text-[#3A2F3D] p-2 md:p-6">{{ currentPrompt }}</h2>
            <button @click="nextPrompt" class="text-3xl text-[#71537A] hover:text-[#8A6A94]">&rsaquo;</button>
          </div>
          <div class="flex flex-col items-center">
            <button @click="toggleListening" class="w-24 h-24 md:w-32 md:h-32 rounded-full flex items-center justify-center mb-6 transition-colors" :class="status === 'Listening...' ? 'bg-red-500 hover:bg-red-600' : 'bg-[#71537A] hover:bg-[#8A6A94]'">
              <svg v-if="status !== 'Listening...'" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="#F5E6FA">
                <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"/>
                <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="#F5E6FA">
                <path d="M6 6h12v12H6z"/>
              </svg>
            </button>
            <p class="text-lg text-[#3A2F3D]">{{ status }}</p>
          </div>
        </div>

        <div ref="chatHistoryContainer" class="bg-white border border-[#DED7E0] rounded-3xl p-6 md:py-8 md:ps-8 md:pe-1 w-full md:w-1/3 overflow-y-auto" style="height: 484px;">
          <h3 class="text-xl md:text-2xl text-center text-[#3A2F3D] mb-4">Your chat with Ally</h3>
          <div v-if="chatHistory.length > 0" class="mb-4 overflow-y-auto md:pe-7" style="max-height: calc(100% - 40px);">
            <div v-for="(entry, index) in chatHistory" :key="index" class="mb-4">
              <p class="font-semibold">👤 You said:</p>
              <p class="ml-4 md:ml-6 mb-2">{{ entry.user }}</p>
              <p class="font-semibold">✨ Ally:</p>
              <p class="ml-4 md:ml-6">{{ entry.ai }}</p>
            </div>
          </div>
          <p v-else class="text-center text-gray-500">No chat history yet.</p>
        </div>
      </div>

      <button @click="generateJournal" class="mt-2 px-4 py-2 bg-[#71537A] text-white rounded-lg hover:bg-[#8A6A94] transition-colors self-center">
        Progress Note
      </button>

      <div v-if="journalEntry" ref="journalEntryContainer" class="mt-8 p-6 bg-white border border-[#DED7E0] rounded-3xl w-full">
        <div class="flex justify-between items-center mb-4">
          <h4 class="text-xl font-semibold text-[#3A2F3D]">Your Progress Entry</h4>
          <div class="flex space-x-2">
            <button @click="copyJournalEntry" class="px-4 py-2 bg-[#71537A] text-white rounded-lg hover:bg-[#8A6A94] transition-colors">
              Copy
            </button>
            <button @click="shareJournalEntry" class="px-4 py-2 bg-[#71537A] text-white rounded-lg hover:bg-[#8A6A94] transition-colors">
              Share
            </button>
          </div>
        </div>
        <p class="text-[#3A2F3D] whitespace-pre-wrap">{{ journalEntry }}</p>
      </div>
    </div>

    <div v-if="showSnackbar" class="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-[#71537A] text-white px-4 py-2 rounded-lg shadow-lg transition-opacity duration-300">
      {{ snackbarMessage }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as Sentry from "@sentry/vue";

export default {
  name: 'App',
  data() {
    return {
      response: '',
      status: 'Press and start speaking',
      chatHistory: [],
      prompts: [
        "What's been on your mind lately 🧠",
        "What's the biggest challenge you're facing in your recovery right now? 🏔️",
        "What's one challenge you've overcome this week? 💪",
        "Who has been a positive influence in your life lately? 🌟",
        "What's a new healthy habit you'd like to develop? 🌱",
        "What's one thing you appreciate about your support system? 🙏",,
        "What's a positive change you've noticed in your life recently? 🌈",
        "What's one thing you've learned about addiction that you wish you knew earlier? 📚",
        "What's one thing you're grateful for in your recovery journey? 🌿",
        "What's one thing you've learned about yourself in recovery? 🧠",
        "What's a goal you're working towards right now? 🎯",
        "What's a positive change you've noticed in your environment recently? 🌿",
        "How have you celebrated a small win this week? 🎉",
        "Who in your support system has been most helpful, and how? 🙏",
        "What's one thing you're looking forward to in your recovery journey? 🌅",
        "Describe a moment when you felt tempted but chose recovery instead. What helped you make that choice? 🛡️"
      ],
      currentPromptIndex: 0,
      journalEntry: '',
      showSnackbar: false,
      snackbarMessage: '',
      mediaRecorder: null,
      mics: [],
      selectedMic: null,
      transcription: '',
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audio: null,
      isMobile: false,
    };
  },
  computed: {
    currentPrompt() {
      return this.prompts[this.currentPromptIndex];
    }
  },
  async mounted() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.mics = devices.filter(device => device.kind === 'audioinput');
      if (this.mics.length > 0) {
        this.selectedMic = this.mics[0].deviceId;
      }
      if (this.audioContext && this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
      }
    } catch (error) {
      console.error('Error enumerating audio devices:', error);
    }
    
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },
  methods: {
    randomizePrompt() {
      this.currentPromptIndex = Math.floor(Math.random() * this.prompts.length);
    },
    previousPrompt() {
      this.currentPromptIndex = (this.currentPromptIndex - 1 + this.prompts.length) % this.prompts.length;
    },
    nextPrompt() {
      this.currentPromptIndex = (this.currentPromptIndex + 1) % this.prompts.length;
    },
    async toggleListening() {
      if (this.status === 'Listening...') {
        this.status = 'Processing your thoughts...';
        try {
          await this.stopListening();
          // Wait for transcription to be available
          await this.waitForTranscription();
          if (this.transcription) {
            await this.sendMessageToBackend(this.transcription.trim());
          } else {
            window.alert('No transcription available');
          }
        } catch (error) {
          console.error('Error in toggleListening:', error);
          this.status = 'Error occurred. Please try again.';
        } finally {
          this.status = 'Press and start speaking';
        }
      } else {
        try {
          await this.startListening();
        } catch (error) {
          console.error('Error starting listening:', error);
          this.status = 'Error starting. Please try again.';
        }
      }
    },
    async waitForTranscription() {
      return new Promise((resolve, reject) => {
        const checkTranscription = setInterval(() => {
          if (this.transcription) {
            clearInterval(checkTranscription);
            clearTimeout(timeout);
            resolve();
          }
        }, 100);

        const timeout = setTimeout(() => {
          clearInterval(checkTranscription);
          if (this.transcription) {
            resolve();
          } else {
            reject(new Error('Transcription timeout'));
          }
        }, 10000); // 10 second timeout
      });
    },
    async startListening() {
      try {
        if (this.mediaRecorder !== null) {
          this.mediaRecorder.stop();
        }
        // Stop audio playback
        if (this.audio !== null) {
          this.audio.pause();
          this.audio.currentTime = 0;
        }
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: this.audioChunks[0]?.type || 'audio/wav' });
          await this.sendToDeepgram(audioBlob);
        };

        this.status = 'Listening...';
        this.mediaRecorder.start(1000);
        this.isRecording = true;
      } catch (error) {
        console.error('Error accessing microphone:', error);
        this.status = 'Error starting recording';
      }
    },

    async stopListening() {
      return new Promise((resolve, reject) => {
        const cleanup = () => {
          if (this.mediaRecorder && this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
          }
          this.isRecording = false;
        };

        if (this.mediaRecorder && this.isRecording) {
          this.mediaRecorder.onstop = async () => {
            try {
              await this.processAudioAndTranscribe();
              cleanup();
              resolve();
            } catch (error) {
              console.error('Error processing audio:', error);
              cleanup();
              reject(error);
            }
          };
          try {
            this.mediaRecorder.stop();
          } catch (error) {
            console.error('Error stopping mediaRecorder:', error);
            cleanup();
            reject(error);
          }
        } else {
          console.log('MediaRecorder not active or already stopped');
          cleanup();
          resolve();
        }

        // Failsafe: resolve after a timeout even if onstop doesn't fire
        setTimeout(() => {
          if (this.isRecording) {
            console.warn('stopListening timed out, forcing cleanup');
            cleanup();
            reject(new Error('Listening stop timeout'));
          }
        }, 5000); // 5 second timeout
      });
    },
    async processAudioAndTranscribe() {
      if (this.audioChunks.length > 0) {
        const audioBlob = new Blob(this.audioChunks, { type: this.audioChunks[0].type || 'audio/wav' });
        await this.sendToDeepgram(audioBlob);
        
        // Wait for transcription to be available
        let attempts = 0;
        while (!this.transcription && attempts < 30) {
          await new Promise(resolve => setTimeout(resolve, 100));
          attempts++;
        }
        
        if (!this.transcription) {
          throw new Error('Transcription not received in time');
        }
      } else {
        console.warn('No audio data to process');
      }
      Sentry.captureMessage("Microphone button toggled");
    },

    async sendToDeepgram(audioBlob) {
      const url = 'https://api.deepgram.com/v1/listen';

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${process.env.VUE_APP_DEEPGRAM_API_KEY}`,
            'Content-Type': audioBlob.type || 'audio/wav'
          },
          body: audioBlob
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.transcription = data.results.channels[0].alternatives[0].transcript;
      } catch (error) {
        console.error('Error sending audio to Deepgram:', error);
        this.status = 'Error processing audio';
        this.transcription = `Error processing audio: ${error.message}`;
        throw error; // Re-throw the error to be caught by the calling function
      }
    },
    async sendMessageToBackend(message) {
      try {
        Sentry.captureMessage("Sending message to backend", {
          extra: {
            message_length: message.length,
            prompt: this.currentPrompt
          }
        });

        const lastAIMessage = this.chatHistory.length > 0 ? this.chatHistory[this.chatHistory.length - 1].ai : null;
        const questionAsked = this.chatHistory.length === 0 ? this.currentPrompt : lastAIMessage;
        const response = await axios.post('/api/v1/voice_chat', { 
          message: message,
          prompt: questionAsked
        }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        this.response = response.data.response;
        this.chatHistory.push({ user: message, ai: this.response });
        this.speakResponse(this.response);

        Sentry.captureMessage("Response received from backend", {
          extra: {
            response: this.response
          }
        });
      } catch (error) {
        console.error('Error in sendMessageToBackend:', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.response = `Server error: ${error.response.status} - ${error.response.data.error || 'Unknown error'}`;
        } else if (error.request) {
          // The request was made but no response was received
          this.response = 'No response from server. Please check your internet connection.';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.response = `Error: ${error.message}`;
        }
        this.chatHistory.push({ user: message, ai: this.response });
        this.status = 'Error';
      } finally {
        this.status = 'Press and start speaking';
      }
    },
    speakResponse(text) {
      const url = 'https://api.deepgram.com/v1/speak?model=aura-asteria-en'; 
      const options = { 
        method: 'POST', 
        headers: {'Content-Type': 'application/json', 'Authorization': `Token ${process.env.VUE_APP_DEEPGRAM_API_KEY}`,}, 
        body: JSON.stringify({text: text}) 
      }; 
      
      fetch(url, options) 
        .then(response => response.blob())
        .then(blob => {
          this.audio = new Audio();
          this.audio.src = URL.createObjectURL(blob);
          
          this.audio.play()
        })
        .catch(error => console.error('Error:', error));
    },

    async generateJournal() {
      this.status = 'Generating note...';
      this.mediaRecorder.stop();
      // Stop audio playback
      if (this.audio !== null) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      try {
        const messages = this.chatHistory.map(entry => ({
          user: entry.user,
          ai: entry.ai
        }));
        const response = await axios.post('/api/v1/voice_chat/generate_journal', { messages }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        this.journalEntry = response.data.journal_entry;
        this.scrollToJournal();
      } catch (error) {
        console.error('Error generating journal:', error);
        this.journalEntry = 'Error: ' + (error.response?.data?.error || error.message);
      } finally {
        this.status = 'Press and start speaking';
      }
    },

    // JOURNAL METHODS
    shareJournalEntry() {
      if (navigator.share) {
        navigator.share({
          title: 'My Journal Entry',
          text: this.journalEntry,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
      } else {
        navigator.clipboard.writeText(this.journalEntry)
          .then(() => {
            alert('Journal entry copied to clipboard!');
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      }
    },
    copyJournalEntry() {
      navigator.clipboard.writeText(this.journalEntry)
        .then(() => {
          this.showSnackbarMessage('Copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
          this.showSnackbarMessage('Failed to copy');
        });
    },
    showSnackbarMessage(message) {
      this.snackbarMessage = message;
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3000);
    },
    scrollToJournal() {
      this.$nextTick(() => {
        const container = this.$refs.journalEntryContainer;
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}
</style>